@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

.standard-box-shadow{
  box-shadow: 3px 3px 15px rgba(0,0,0,0.1);
}

// Fix overlap of colorpickers and links in React-draft-wysiwyg
.setting-col .rdw-colorpicker-modal{
  left:-100px;
  .rdw-colorpicker-modal-options{
    overflow: auto;
  }
}
.setting-col .rdw-link-modal{
  left:-105px;
}

// // RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}
.noti-icon .badge {
  left: 23px;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.react-kanban-column{
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

// .form-check-input {
//   position: absolute !important;
//   left: 0 !important;
//   z-index: -1 !important;
//   width: 1rem !important;
//   height: 1.10938rem !important;
//   opacity: 0 !important;
// }


//chat style dot
// .conversation-list .dropdown .dropdown-toggle{
//   font-size: 18px !important;
//   padding: 4px !important;
//   color: #74788d !important;
// }
.flatpickr-calendar .flatpickr-day.selected,
.flatpickr-calendar .flatpickr-day.startRange,
.flatpickr-calendar .flatpickr-day.endRange,
.flatpickr-calendar .flatpickr-day.selected.inRange,
.flatpickr-calendar .flatpickr-day.startRange.inRange,
.flatpickr-calendar .flatpickr-day.endRange.inRange,
.flatpickr-calendar .flatpickr-day.selected:focus,
.flatpickr-calendar .flatpickr-day.startRange:focus,
.flatpickr-calendar .flatpickr-day.endRange:focus,
.flatpickr-calendar .flatpickr-day.selected:hover,
.flatpickr-calendar .flatpickr-day.startRange:hover,
.flatpickr-calendar .flatpickr-day.endRange:hover,
.flatpickr-calendar .flatpickr-day.selected.prevMonthDay,
.flatpickr-calendar .flatpickr-day.startRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.endRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.selected.nextMonthDay,
.flatpickr-calendar .flatpickr-day.startRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.endRange.nextMonthDay{
  background: $primary;
  border-color: $primary;
}


//dropdown
.dropdown-menu.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  // .control-dots {
  //   margin-top: 35px !important;
  // }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}
.carousel {
  .control-dots {
    margin: -5px 0px;
  }
  .dot {
    background : #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
    
  }
}

//Two verification Page
.verification{
 input{
  &:focus{
    border: none;
    outline: none !important;
  }
 }
}

@media (max-width: 768px) {
  .verification{
    input{
      width: 40px !important;
   
    }
   }
}

@media (max-width: 320px) {
  .verification{
    input{
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
   
    }
   }
}

//Two verification Page - 2
.verification-2{
  input{
   &:focus{
     border: none;
     outline: none !important;
   }
  }
 }
 
 @media (max-width: 768px) {
   .verification-2{
     input{
       width: 40px !important;
       font-size: 14px;
    
     }
    }
 }
 
 @media (max-width: 320px) {
   .verification-2{
     input{
       width: 30px !important;
       height: 35px !important;
       font-size: 15px !important;
    
     }
    }
 }

 //Calendar
 .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}
.close{
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: .5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

.card-columns {
  column-count: 3;
  grid-column-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
  grid-column-gap: 24px;
  column-gap: 24px;
  orphans: 1;
  widows: 1;
}

.statistics-left{
  i{
    color: $primary;
  }
}
@keyframes slide-in-right {
  0% {
    opacity:0;
    left:100%;
  }
  90%{opacity:0.3}
  100% {
    opacity:1;
    left:0;
  }
}

@keyframes slide-in-left {
  0% {
    opacity:0;
    right:100%;
  }

  100% {
    opacity:1;
    right:0;
  }
}
.slide-in-right.tab-pane.active{
  position:relative;
  animation-name: slide-in-right;
  animation-duration: 0.3s;
}
.slide-in-left.tab-pane.active{
  position:relative;
  animation-name: slide-in-left;
  animation-duration: 0.3s;
}

//display status in display list
.display-status{

  padding: 4px 25px;
  color:white;
  margin-left: 15px;
  border-radius: 15px;

  &.enabled{
    background: rgb(52, 195, 143);
  }
  &.disabled{
    background: #F46A6A;
  }
  &.draft{
    background: rgb(241, 180, 76)
  }

}

.display-editor-toolbar{
  height: $display-editor-toolbar-height;
}

.logo-wrapper{
  max-width: 170px;
}
.setting-col{
  z-index: 1001;
}
.preview-col{
  position:sticky;
  top:0;
  display: flex;
  justify-content: center;
  height: calc(100vh - (#{$display-editor-toolbar-height} + #{$header-height} + #{$topnav-height}));
  margin:0;
  padding:0;
  background: grey;
  z-index: 1000;
}

.uploadGalleryWrapper{
  columns: 6 200px;
  column-gap: 1rem;

  .photo-item{
    margin: 0 1rem 1rem 0;
    display: inline-block;
    width: 100%;
    position: relative;
    opacity: 1;
    transition: all 0.3s;
  }
  .photo-item:hover{
    opacity: 0.8;
  }
  .cover-link{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
}

.tooltip-inner a{
  color: #007bff;
}

.tox-statusbar__branding{
  display:none !important
}
.tox .tox-toolbar__group{
  padding:0 5px !important;
}